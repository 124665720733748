import request from '@/utils/request'

const urlPrefix = 'reports'

// source-report
export function getSourceReport (id) {
  return request.get(`${urlPrefix}/source-reports/${id}`)
}

export function deleteSourceReport (id) {
  return request.delete(`${urlPrefix}/source-reports/${id}`)
}

export function getSourceReportList (params) {
  return request.get(`${urlPrefix}/source-reports`, { params })
}

export function createSourceReport (data) {
  return request.post(`${urlPrefix}/source-reports`, data)
}

// source-task-report
export function createSourceTaskReport (data) {
  return request.post(`${urlPrefix}/source-reports/cron-tasks`, data)
}

export function getSourceTaskReportList (params) {
  return request.get(`${urlPrefix}/source-reports/cron-tasks`, { params })
}

export function deleteSourceTaskReport (id) {
  return request.delete(`${urlPrefix}/source-reports/cron-tasks/${id}`)
}

export function getSourceTaskReport (id) {
  return request.get(`${urlPrefix}/source-reports/cron-tasks/${id}`)
}

export function updateSourceTaskReport (id, data) {
  return request.put(`${urlPrefix}/source-reports/cron-tasks/${id}`, data)
}

// source-usage-report
export function getSourceUsageReport (id) {
  return request.get(`${urlPrefix}/source-usage-reports/${id}`)
}

export function deleteSourceUsageReport (id) {
  return request.delete(`${urlPrefix}/source-usage-reports/${id}`)
}

export function getSourceUsageReportList (params) {
  return request.get(`${urlPrefix}/source-usage-reports`, { params })
}

export function createSourceUsageReport (data) {
  return request.post(`${urlPrefix}/source-usage-reports`, data)
}

export function exportSourceUsageReport (data) {
  return request({
    url: `${urlPrefix}/source-usage-reports/export`,
    responseType: 'blob',
    method: 'post',
    data
  })
}

// source-usage-task-report
export function createSourceUsageTaskReport (data) {
  return request.post(`${urlPrefix}/source-usage-reports/cron-tasks`, data)
}

export function deleteSourceUsageTaskReport (id) {
  return request.delete(`${urlPrefix}/source-usage-reports/cron-tasks/${id}`)
}

export function getSourceUsageTaskReportList (params) {
  return request.get(`${urlPrefix}/source-usage-reports/cron-tasks`, { params })
}

export function getSourceUsageTaskReport (id) {
  return request.get(`${urlPrefix}/source-usage-reports/cron-tasks/${id}`)
}

export function updateSourceUsageTaskReport (id, data) {
  return request.put(`${urlPrefix}/source-usage-reports/cron-tasks/${id}`, data)
}

// source-usage-settings-report
export function getSourceUsageReportSetting (id) {
  return request.get(`${urlPrefix}/source-usage-report-settings/${id}`)
}

export function deleteSourceUsageReportSetting (id) {
  return request.delete(`${urlPrefix}/source-usage-report-settings/${id}`)
}

export function getSourceUsageReportSettingList (params) {
  return request.get(`${urlPrefix}/source-usage-report-settings`, { params })
}

export function createSourceUsageReportSetting (data) {
  return request.post(`${urlPrefix}/source-usage-report-settings`, data)
}

// service-report
export function getServiceReport (id) {
  return request.get(`${urlPrefix}/service-reports/${id}`)
}

export function deleteServiceReport (id) {
  return request.delete(`${urlPrefix}/service-reports/${id}`)
}

export function getServiceReportList (params) {
  return request.get(`${urlPrefix}/service-reports`, { params })
}

export function createServiceReport (data) {
  return request.post(`${urlPrefix}/service-reports`, data)
}

// service-task-report
export function createServiceTaskReport (data) {
  return request.post(`${urlPrefix}/service-reports/cron-tasks`, data)
}

export function getServiceTaskReport (id) {
  return request.get(`${urlPrefix}/service-reports/cron-tasks/${id}`)
}

export function deleteServiceTaskReport (id) {
  return request.delete(`${urlPrefix}/service-reports/cron-tasks/${id}`)
}

export function getServiceTaskReportList (params) {
  return request.get(`${urlPrefix}/service-reports/cron-tasks`, { params })
}

export function updateServiceTaskReport (id, data) {
  return request.put(`${urlPrefix}/service-reports/cron-tasks/${id}`, data)
}

// network-interface-usage-report
export function getNetworkInterfaceUsageReport (id) {
  return request.get(`${urlPrefix}/network-interface-usage-reports/${id}`)
}

export function deleteNetworkInterfaceUsageReport (id) {
  return request.delete(`${urlPrefix}/network-interface-usage-reports/${id}`)
}

export function getNetworkInterfaceUsageReportList (params) {
  return request.get(`${urlPrefix}/network-interface-usage-reports`, { params })
}

export function createNetworkInterfaceUsageReport (data) {
  return request.post(`${urlPrefix}/network-interface-usage-reports`, data)
}
