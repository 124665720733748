<template>
  <div id="interface-usage-report">
    <a-spin :spinning="spinning" style="min-height: 400px">
      <template v-if="displayCards[0]">
        <a-popconfirm
          v-show="activeTab !== '全部'"
          placement="bottomRight"
          :title="`当前导出数据为 ${activeTab},是否继续导出?`"
          ok-text="继续"
          cancel-text="导出全部"
          @confirm="exportPdf"
          @cancel="
            () => {
              activeTab = '全部'
              exportPdf()
            }
          "
        >
          <a-button
            type="primary"
            style="position: absolute; right: 40px; top: 40px"
          >
            导出
          </a-button>
        </a-popconfirm>
        <a-button
          v-show="activeTab === '全部'"
          @click="exportPdf"
          type="primary"
          style="position: absolute; right: 0px; top: 24px"
        >
          导出
        </a-button>
        <div id="pdfDom">
          <header
            :style="{
              fontSize: '24px',
              fontWeight: 600,
              color: '#096dd9',
              lineHeight: '42px',
              textAlign: 'center',
              padding: '52px 80px 0'
            }"
          >
            {{ detail.name }}
          </header>
          <div v-if="detail.name" style="text-align: center; font-size: 14px">
            统计周期：{{
              `${detail.datetime_from || '-'} ~  ${detail.datetime_to || '-'}`
            }}
          </div>
          <div class="small-module-title" style="margin-top: 24px">
            <a-icon type="bar-chart"></a-icon>
            <span> 闲置时长统计</span>
            <a-tooltip title="最后一次down的时长统计">
              <a-icon
                type="question-circle"
                style="font-size: 13px; margin-left: 4px"
              />
            </a-tooltip>
          </div>
          <div style="display: flex">
            <div
              class="pdf-split-flag"
              v-for="(item, index) in displayCards"
              :key="index"
              style="margin: 0 10px; width: 100%; max-width: 300px"
            >
              <statistics-card
                :color="colors[index]"
                :current-detail="item"
                :prevData="displayCards"
                :index="index"
                @changeTab="v => (activeTab = v)"
              ></statistics-card>
            </div>
          </div>
          <div
            class="small-module-title pdf-split-flag'"
            style="margin-top: 24px"
          >
            <a-icon type="bar-chart"></a-icon>
            <span> 闲置网口列表</span>
          </div>
          <div style="position: absolute; text-align: right; right: 16px; z-index: 999;">
              <setting-button
                :columns="columnsAll"
                :default-selected="columnDefaultSelected"
                @selected="updateColumns"
              ></setting-button>
            </div>
          <a-tabs :animated="true" type="card" v-if="displayCards.length" v-model="activeTab">
            <a-tab-pane
              v-for="(item, index) in displayCards"
              :key="item.title"
              :tab="item.title"
            >
              <a-table
                v-if="item.downData.length"
                class="split-table custom-table"
                :columns="columns"
                :data-source="item.downData"
                :pagination="false"
                :row-key="record => `${record.name}-${record.deviceName}`"
                :row-class-name="() => 'pdf-split-flag'"
                style="flex: 1; margin-top: 14px"
              >
                <template slot="downDate" slot-scope="t, record">
                  <a-tag color="blue">开始日期</a-tag>{{ record.startDate }}
                  <div style="height: 4px"></div>
                  <a-tag color="orange">结束日期</a-tag>{{ record.endDate }}
                </template>
                <template slot="downDays" slot-scope="t, record">
                  {{ getDownDays(record) }} 天
                </template>
                <template slot="valueMaps" slot-scope="text">
                  <ul v-if="text && text.length" style="padding-left: 4px;">
                    <li v-for="item in text" :key="item.key">{{item.key}}：{{item.value}}</li>
                  </ul>
                  <span v-else>-</span>
                </template>
                <template slot="status" slot-scope="t, record">
                  <div style="width: 100%; max-width: 100%">
                    <down-chart
                      :chart-id="`${index}-${record.name}-${record.deviceName}-down-chart`"
                      :chartData="record.history"
                    ></down-chart>
                  </div>
                </template>
              </a-table>
              <div
                v-else
                style="height: 350px; position: relative; overflow: hidden"
              >
                <empty-component
                  :imgUrl="require('@/assets/images/info_empty.png')"
                  :body-style="{
                    height: '200px',
                    marginTop: '70px'
                  }"
                  :description-style="{
                    marginTop: '-12px',
                    marginRight: '8px',
                    color: 'rgba(0,0,0,.55)'
                  }"
                  description="暂无数据"
                >
                </empty-component>
              </div>
            </a-tab-pane>
          </a-tabs>
          <div
            v-else
            style="height: 350px; position: relative; overflow: hidden"
          >
            <empty-component
              :imgUrl="require('@/assets/images/info_empty.png')"
              :body-style="{
                height: '200px',
                marginTop: '70px'
              }"
              :description-style="{
                marginTop: '-12px',
                marginRight: '8px',
                color: 'rgba(0,0,0,.55)'
              }"
              description="暂无闲置网口数据"
            >
            </empty-component>
          </div>
        </div>
      </template>
      <div
        v-else
        style="height: 350px; position: relative; overflow: hidden"
      >
        <empty-component
          :imgUrl="require('@/assets/images/info_empty.png')"
          :body-style="{
            height: '200px',
            marginTop: '70px'
          }"
          :description-style="{
            marginTop: '-12px',
            marginRight: '8px',
            color: 'rgba(0,0,0,.55)'
          }"
          description="暂无数据"
        >
        </empty-component>
      </div>
    </a-spin>
  </div>
</template>

<script>
import moment from 'moment'
import PdfLoader from '@/utils/htmlToPdf'
import { getNetworkInterfaceUsageReport } from '@/api/report'
import EmptyComponent from '@/components/EmptyComponent.vue'
import StatisticsCard from './modules/StatisticsCard.vue'
import DownChart from './modules/DownChart.vue'
import SettingButton from '@/components/button/SettingButton.vue'

const colors = ['#c126f7', '#ff427b', '#ff8e51', '#f7b500', '#f9e043']

export default {
  name: 'NetworkInterfaceUsageReportDetail',
  components: {
    EmptyComponent,
    StatisticsCard,
    DownChart,
    SettingButton
  },
  data () {
    return {
      colors,
      activeTab: '全部',
      spinning: false,
      detail: {
        statistic: {
          data: [],
          total: 0
        }
      },
      columnsAll: [
        {
          dataIndex: 'name',
          title: '网口名称',
          width: 180
        },
        {
          dataIndex: 'deviceName',
          title: '设备名称',
          width: 130
        },
        {
          dataIndex: 'monitor_address',
          title: 'IP 地址',
          width: 160
        },
        {
          dataIndex: 'down_date',
          title: '最后一次down周期',
          width: 180,
          scopedSlots: {
            customRender: 'downDate'
          }
        },
        {
          dataIndex: 'down_days',
          title: 'down时长',
          width: 130,
          align: 'center',
          scopedSlots: {
            customRender: 'downDays'
          },
          sorter: (a, b) => this.getDownDays(a) - this.getDownDays(b)
        },
        {
          dataIndex: 'value_maps',
          title: '值映射',
          width: 140,
          scopedSlots: {
            customRender: 'valueMaps'
          }
        },
        {
          dataIndex: 'status',
          title: '状态趋势',
          width: 140,
          align: 'center',
          scopedSlots: {
            customRender: 'status'
          }
        }
      ],
      columnDefaultSelected: [
        'name',
        'deviceName',
        'monitor_address',
        'down_date',
        'down_days',
        'status'
      ],
      displayCards: [],
      activeKey: [],
      columns: []
    }
  },
  mounted () {
    this.fetch()
    this.updateColumns(this.columnsAll.filter(
      column => this.columnDefaultSelected.indexOf(column.dataIndex) !== -1
    ))
  },
  methods: {
    fetch () {
      this.spinning = true
      getNetworkInterfaceUsageReport(this.$route.params.id)
        .then(res => {
          this.detail = res.data
          this.getCardTypes()
          if (res.data.statistic && res.data.statistic.total) this.getDownData(res.data.statistic.data)
        })
        .finally(() => {
          this.spinning = false
        })
    },
    exportPdf () {
      this.spinning = true
      this.$nextTick(() => {
        const ele = document.getElementById('pdfDom')
        const pdf = new PdfLoader(ele, this.detail.name, 'pdf-split-flag')
        pdf.outPdf().then(() => {
          this.spinning = false
        })
      })
    },
    getDownData (data) {
      data.forEach(device => {
        const newDevice = {
          ...device,
          network_interfaces: []
        }
        if (newDevice.value_maps && newDevice.value_maps.length) {
          // 寻找down对应的值
          const downObj = newDevice.value_maps.find(
            item => item.value === 'down'
          )
          if (downObj) {
            if (device.network_interfaces.length) {
              for (
                let index = 0;
                index < device.network_interfaces.length;
                index++
              ) {
                const networkInterface = device.network_interfaces[index]
                const reverseInterface = JSON.parse(
                  JSON.stringify(networkInterface)
                )
                if (
                  reverseInterface.history &&
                  reverseInterface.history.length
                ) {
                  reverseInterface.history.reverse()
                  if (
                    reverseInterface.history[0].value.toString() === downObj.key
                  ) {
                    // 计算网口down的开始时间和结束时间
                    let date
                    const notDownIndex = reverseInterface.history.findIndex(
                      item => item.value.toString() !== downObj.key
                    )
                    if (notDownIndex !== -1) {
                      date = moment(
                        reverseInterface.history[notDownIndex - 1].timestamp
                      ).format('YYYY-MM-DD')
                    } else {
                      date = moment(
                        networkInterface.history[0].timestamp
                      ).format('YYYY-MM-DD')
                    }
                    networkInterface.startDate = date
                    networkInterface.endDate = moment(
                      reverseInterface.history[0].timestamp
                    ).format('YYYY-MM-DD')

                    this.displayCards.forEach((card, cardIndex) => {
                      if (cardIndex === 0) {
                        card.downData.push({
                          deviceName: device.name,
                          monitor_address: device.monitor_address,
                          value_maps: device.value_maps,
                          ...networkInterface
                        })
                      } else {
                        const cumputedDate = card.diffs.reduce((prev, cur) => {
                          return moment(prev)
                            .subtract(cur.value, cur.unit)
                            .format('YYYY-MM-DD')
                        }, networkInterface.endDate)
                        if (moment(networkInterface.startDate).isSameOrBefore(cumputedDate)) {
                          card.downData.push({
                            deviceName: device.name,
                            value_maps: device.value_maps,
                            monitor_address: device.monitor_address,
                            ...networkInterface
                          })
                        }
                      }
                    })
                  }
                }
              }
              this.activeKey.push(device.name)
            }
          }
        }
      })
    },
    getDownDays (record) {
      return moment(record.endDate).diff(record.startDate, 'day')
    },
    collapseIcon (key) {
      return this.activeKey.findIndex(item => item === key) !== -1
        ? 'up-circle'
        : 'down-circle'
    },
    getCardTypes () {
      const diffMonth = moment(this.detail.datetime_to).diff(
        this.detail.datetime_from,
        'month'
      )
      const displayCards = []
      if (diffMonth < 1) {
        const diffDays = moment(this.detail.datetime_to).diff(
          this.detail.datetime_from,
          'day'
        )
        if (diffDays >= 15) {
          displayCards.unshift({
            title: '≥ 15天',
            downData: [],
            startDate: moment(this.detail.datetime_to)
              .subtract(15, 'day')
              .format('YYYY-MM-DD'),
            diffs: [{ unit: 'day', value: 15 }]
          })
        }
        if (diffDays >= 7) {
          displayCards.unshift({
            title: '≥ 7天',
            downData: [],
            startDate: moment(this.detail.datetime_to)
              .subtract(7, 'day')
              .format('YYYY-MM-DD'),
            diffs: [{ unit: 'day', value: 7 }]
          })
        }
        if (diffDays >= 3) {
          displayCards.unshift({
            title: '≥ 3天',
            downData: [],
            startDate: moment(this.detail.datetime_to)
              .subtract(3, 'day')
              .format('YYYY-MM-DD'),
            diffs: [{ unit: 'day', value: 3 }]
          })
        }
      } else {
        displayCards.push(
          {
            title: '≥ 15天',
            downData: [],
            startDate: moment(this.detail.datetime_to)
              .subtract(15, 'day')
              .format('YYYY-MM-DD'),
            diffs: [{ unit: 'day', value: 15 }]
          },
          {
            title: '≥ 1个月',
            downData: [],
            startDate: moment(this.detail.datetime_to)
              .subtract(1, 'month')
              .format('YYYY-MM-DD'),
            diffs: [{ unit: 'month', value: 1 }]
          }
        )
        if (diffMonth >= 6) {
          displayCards.push(
            {
              title: '≥ 3个月',
              downData: [],
              startDate: moment(this.detail.datetime_to)
                .subtract(3, 'month')
                .format('YYYY-MM-DD'),
              diffs: [{ unit: 'month', value: 3 }]
            },
            {
              title: '≥ 6个月',
              downData: [],
              startDate: moment(this.detail.datetime_to)
                .subtract(6, 'month')
                .format('YYYY-MM-DD'),
              diffs: [{ unit: 'month', value: 6 }]
            }
          )
        } else if (diffMonth >= 4) {
          displayCards.push(
            {
              title: '≥ 2个月',
              downData: [],
              startDate: moment(this.detail.datetime_to)
                .subtract(2, 'month')
                .format('YYYY-MM-DD'),
              diffs: [{ unit: 'month', value: 2 }]
            },
            {
              title: '≥ 4个月',
              downData: [],
              startDate: moment(this.detail.datetime_to)
                .subtract(4, 'month')
                .format('YYYY-MM-DD'),
              diffs: [{ unit: 'month', value: 4 }]
            }
          )
        } else if (diffMonth >= 2) {
          displayCards.push(
            {
              title: '≥ 1个半月',
              downData: [],
              startDate: moment(this.detail.datetime_to)
                .subtract(1, 'month')
                .subtract(15, 'day')
                .format('YYYY-MM-DD'),
              diffs: [
                { unit: 'month', value: 1 },
                { unit: 'day', value: 15 }
              ]
            },
            {
              title: '≥ 2个月',
              downData: [],
              startDate: moment(this.detail.datetime_to)
                .subtract(2, 'month')
                .format('YYYY-MM-DD'),
              diffs: [{ unit: 'month', value: 2 }]
            }
          )
        }
      }
      displayCards.unshift({ title: '全部', downData: [] })
      this.displayCards = displayCards
    },
    updateColumns (columns) {
      this.columns = [...columns]
    }
  }
}
</script>

<style lang="less">
#interface-usage-report {
  background: #ffffff;
  border-radius: 8px;
  padding: 0 26px 22px 26px;
  position: relative;

  .outPdf-thead {
    height: 40px !important;
    th {
      padding: 0 16px;
      background: #fff !important;
      height: 40px !important;
      font-size: 14px !important;
      border: none !important;
      box-sizing: border-box !important;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
    }
  }

  .table-card {
    box-shadow: none;
    .ant-card-grid {
      box-shadow: none;
    }
  }

  .small-module-title {
    height: 35px;
    background: #e4f1ff;
    border-radius: 8px;
    color: #096dd9;
    padding: 0 16px;
    margin-bottom: 16px;

    i {
      font-size: 16px;
      height: 20px;
      vertical-align: sub;
    }
    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 37px;
      vertical-align: middle;
    }
  }

  .ant-collapse-borderless {
    background: transparent;

    & > .ant-collapse-item {
      // border: 1px solid #e8e8e8 !important;

      .collapse-title {
        padding-left: 10px;
        position: relative;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #096dd9;
        font-size: 16px;
        height: 18px;
        line-height: 18px;
        font-weight: 500;

        .collapse-icon {
          color: #5e98e3;
          font-size: 10px;
          vertical-align: middle;
          margin: 4px 0 3px 8px;
        }
      }
    }

    .ant-descriptions-view {
      padding: 14px 12px 0 16px;
      border-radius: 8px;
      background: aliceblue;

      .ant-descriptions-item-label {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  .base-info-container {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    padding: 0 64px;
    margin: 32px 0 32px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .base-info {
      padding: 0 16px 0 0;
    }
  }

  .alert-info-container {
    padding: 14px 21px;
    border-radius: 8px;
    margin: 8px 0 24px 0;
    .title {
      font-size: 16px;
      line-height: 26px;
      font-weight: 500;
    }
    .message {
      font-size: 14px;
      line-height: 26px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .alert-info-container_normal {
    background: #eef8e9;
    .title {
      color: #52c41a;
    }
  }
  .alert-info-container_abnormal {
    background: #ffecec;
    .title {
      color: #ff4d4f;
    }
  }
  .alert-info-container_scaling_down {
    background: #eaf2fc;
    .title {
      color: #096dd9;
    }
  }
  .alert-info-container_scaling_up {
    background: #fff9f5;
    .title {
      color: #fa6400;
    }
  }
}
</style>
