<template>
  <a-empty
    v-if="showDescription"
    :image="imgUrl"
    :image-style="imgStyle"
  >
    <span slot="description">
      <div class="base-description" :style="descriptionStyle">
        <slot name="descriptionText">
          {{ description }}
        </slot>
      </div>
    </span>
    <slot name="customContent"></slot>
  </a-empty>
  <a-empty
    v-else
    :image="imgUrl"
    :image-style="imgStyle"
    :description="false"
  ><slot name="customContent"></slot></a-empty>
</template>

<script>

export default {
  name: 'EmptyComponent',
  props: {
    description: {
      type: String,
      default: '暂无告警'
    },
    descriptionStyle: {
      type: Object,
      default: () => { return {} }
    },
    bodyStyle: {
      type: Object,
      default: () => { return {} }
    },
    imgUrl: {
      default: require('@/assets/images/alert_empty.png')
    },
    showDescription: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    imgStyle () {
      const keys = Object.keys(this.bodyStyle)
      const obj = {
        height: '120px'
      }
      if (keys.length) {
        keys.forEach(key => {
          obj[key] = this.bodyStyle[key]
        })
      }
      return obj
    }
  }
}
</script>
<style lang="less" scoped>
.base-description {
  margin-top: -20px;
  color: #d1d1d1;
  font-size: 14px;
}
</style>
