<template>
  <div class="container">
    <div class="body" :style="bodyStyle" @click="$emit('changeTab', currentDetail.title)">
      <div class="title">
        {{ currentDetail.title || '-'}}
      </div>
      <span class="value" :style="{color}">
        {{ currentDetail.downData.length }}
      </span>
      <div class="description" v-if="index !== 0">
        <a-tag color="green" style="border-radius: 6px;">
          <span>{{ getDescCount(index) }}</span>
          <a-icon type="caret-down" theme="filled" style="font-size: 11px; margin-left: 3px; vertical-align: middle;"/>
        </a-tag>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'StatisticsCard',
  props: {
    color: {
      type: String,
      default: '#ffffff'
    },
    currentDetail: {
      type: Object
    },
    prevData: {
      type: Array
    },
    index: {
      type: Number
    }
  },
  computed: {
    bodyStyle () {
      return {
        background: `${this.color}10`
      }
    }
  },
  data () {
    return {
      total: 0
    }
  },
  methods: {
    getTotal (index) {
      let val = 0
      if (this.prevData) {
        let i = 0
        while (i <= index) {
          val += this.prevData[i].downData.length
          i++
        }
      }
      return val
    },
    getDescCount (index) {
      let val = 0
      if (this.prevData) val = this.prevData[index - 1].downData.length - this.prevData[index].downData.length
      return val
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  position: relative;
  height: 120px;
  background: #fff;
  border-radius: 8px;
  width: 100%;
  z-index: 999;
  cursor: pointer;

  .body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-top: 3px solid #fff;
    border-radius: 8px;
    padding: 12px 24px;
    .title {
      color: rgba(0,0,0,.65);
      padding-top: 2px;
    }
    .value {
      font-weight: 500;
      font-size: 32px;
      line-height: 76px;
      padding-left: 4px;
      color: rgba(0,0,0,.85);
      cursor: pointer;
    }
    .description {
      position: absolute;
      bottom: 8px;
      right: 2px;
      font-size: 12px;
    }
  }
}
</style>
