<template>
  <div :id="chartId"></div>
</template>

<script>
import moment from 'moment'
import { Chart } from '@antv/g2'
import { getIntegerTicks } from '@/utils'
// import { greenColor, orangeColor, redColor } from '@/utils/const'

const chart = {}

export default {
  name: 'DownChart',
  props: {
    chartData: {
      type: Array
    },
    chartId: {
      type: String,
      required: true
    }
  },
  mounted () {
    chart[this.chartId] = new Chart({
      container: this.chartId,
      height: 80,
      width: 280,
      appendPadding: [4, 0, 0, 0]
    })
    chart[this.chartId].data(this.formatterData(this.chartData))
    chart[this.chartId].axis('date', false)
    chart[this.chartId].axis('value', {
      grid: {
        line: {
          style: {
            stroke: '#cccccc',
            strokeOpacity: 0.8,
            lineWidth: 1,
            lineDash: [4]
          }
        }
      }
    })
    chart[this.chartId].scale('date', {
      // range: [0, 1]
      nice: true

    })
    chart[this.chartId].scale('value', {
      min: 1,
      tickMethod: getIntegerTicks
    })
    chart[this.chartId]
      .line()
      .position('date*value')
    chart[this.chartId].render()
    // chart[this.chartId].axis('timestamp', false)
    // chart[this.chartId].axis('value', false)
    // chart[this.chartId].legend(false)
    // // chart[this.chartId].tooltip({
    // //   shared: true,
    // //   showTitle: false,
    // //   showMarkers: false,
    // //   itemTpl: '<div style="line-height: 16px; font-size: 12px;margin-bottom: 8px;"><div>请求时间：</div><div>{date}</div><div>请求成功率：</div><div>{value}</div></div>'
    // // })

    // chart[this.chartId].area().position('timestamp*value')

    // chart[this.chartId]
    //   .line(
    //     {
    //       background: {
    //         style: {
    //           radius: 0
    //         }
    //       }
    //     }
    //   )
    //   .position('timestamp*value')
    // .color('value', (val) => {
    //   if (val >= 90) {
    //     return greenColor
    //   } else if (val >= 60) {
    //     return orangeColor
    //   } else {
    //     return redColor
    //   }
    // })
    // .tooltip('date*value', (date, value) => {
    //   return {
    //     date,
    //     value: value + '%'
    //   }
    // })
    // chart[this.chartId].interaction('active-region')

    // chart[this.chartId].render()

    // this.$forceUpdate()
  },
  methods: {
    render () {
      if (this.chartData.length === 0) {
        chart[this.chartId].changeVisible(false)
      } else {
        chart[this.chartId].changeData(this.formatterData(this.chartData))
        chart[this.chartId].changeVisible(true)
      }
    },
    formatterData (data) {
      return data.map(item => {
        return {
          date: moment(item.timestamp).format('YYYY-MM-DD HH:mm'),
          value: item.value
        }
      })
    }
  }
}
</script>
